import { allSupportedFonts, supportedMonospaceFonts, supportedSerifFonts } from "@cymbal/legos";
import Cookies from "js-cookie";

import { format } from "date-fns";

import { LeafLegoComponent as LeafLegoComponentPkg, LegoComponent as LegoComponentPkg } from "@cymbal/legos";
import { LegoTab } from "../components/frames/legoFrame/legoFrame";
import { Event } from "../models/event";
import type {
    AutomationTriggeredEventLegoComponent,
    AutomationUpcomingEventsLegoComponent,
    ColumnLegoComponent,
    EventLegoComponent,
    HorizontalEventLegoComponent,
    LeafLegoComponent,
    LegoComponent,
    LegoComponentGroup,
    LegoComponentGroupRow,
    LegoEventArrangement,
    SingleLegoEventArrangement,
    TextAddLegoComponentType,
    TextLeafLegoComponentType,
} from "../models/legos";
import { MergedOrganizationLegoStyles, NonNullableLegoEventStyle } from "../models/mergedLegos";
import {
    PostLegoComponentBody,
    PostLegoComponentBodyWithAutomationTriggeredEvent,
    PostLegoComponentBodyWithAutomationUpcomingEvents,
    PostLegoComponentBodyWithColumn,
    PostLegoComponentBodyWithEvent,
    PostLegoComponentBodyWithHorizontalEvent,
    PostLegoComponentBodyWithLeaf,
} from "../models/postLegos";
import { adjustForTimezone } from "./date";
import { getImageUrl } from "./img";
import { sortByEventOrdinals } from "./mergedLegos";

export const convertToTextLeafLegoComponentType = (type: TextAddLegoComponentType): TextLeafLegoComponentType => {
    switch (type) {
        case "heading-text":
            return "heading";
        case "subheading-text":
            return "subheading";
        case "title-text":
            return "title";
        case "subtitle-text":
            return "subtitle";
        case "section-text":
            return "section";
        case "body-text":
            return "body";
        case "accent-text":
            return "accent";
        case "caption-text":
            return "caption";
    }
};

export const convertToTextAddLegoComponentType = (type: TextLeafLegoComponentType): TextAddLegoComponentType => {
    switch (type) {
        case "heading":
            return "heading-text";
        case "subheading":
            return "subheading-text";
        case "title":
            return "title-text";
        case "subtitle":
            return "subtitle-text";
        case "section":
            return "section-text";
        case "body":
            return "body-text";
        case "accent":
            return "accent-text";
        case "caption":
            return "caption-text";
    }
};

export const legoFontFamilyDropdownOptions = [...allSupportedFonts]
    .sort((a, b) => {
        if (a < b) {
            return -1;
        }
        if (a > b) {
            return 1;
        }
        return 0;
    })
    .map((fontFamily) => ({
        key: fontFamily,
        value: fontFamily,
        label: fontFamily,
    }));

const LEGOS_STYLE_CUSTOM_COLORS_KEY = "Legos-Style-Custom-Colors";

export const setCookieLegosStyleCustomColors = (colors: string[]) =>
    Cookies.set(LEGOS_STYLE_CUSTOM_COLORS_KEY, JSON.stringify(colors));

export const getCookieLegosStyleCustomColors = () => {
    const cookieValue = Cookies.get(LEGOS_STYLE_CUSTOM_COLORS_KEY);
    return cookieValue ? (JSON.parse(cookieValue) as string[]) : [];
};

export const LEGO_BUILDER_FULLSCREEN_BREAKPOINT = 1100;

export const getFontFamily = (fontFamily: (typeof allSupportedFonts)[number]) => {
    if (supportedMonospaceFonts.includes(fontFamily as any)) {
        return `"${fontFamily}", monospace`;
    } else if (supportedSerifFonts.includes(fontFamily as any)) {
        return `"${fontFamily}", serif`;
    } else {
        return `"${fontFamily}", sans-serif`;
    }
};

export const getLongFormatLegoDateString = (event: Event) =>
    event.start_datetime
        ? format(
              !event.location
                  ? new Date(event.start_datetime)
                  : adjustForTimezone(new Date(event.start_datetime), event.location.timezone, true),
              "EEEE, LLLL d, h:mmaaa",
          )
        : "";

const textTypePriority = {
    title: 1,
    subtitle: 2,
    heading: 3,
    subheading: 4,
    section: 5,
    body: 6,
    accent: 7,
    caption: 8,
} as const;

const sortTextComponentsByPriority = (a: LegoComponentGroupRow, b: LegoComponentGroupRow) => {
    if (
        a.component.type === "leaf" &&
        a.component.leaf_component.type === "text" &&
        b.component.type === "leaf" &&
        b.component.leaf_component.type === "text"
    ) {
        return (
            textTypePriority[a.component.leaf_component.text_component.text_type] -
            textTypePriority[b.component.leaf_component.text_component.text_type]
        );
    }

    return 0;
};

export const getEventTextPreview = (
    eventLegoComponent: EventLegoComponent,
    event: Event,
    mergedOrgLegoStyles: MergedOrganizationLegoStyles,
) => {
    const eventRows = eventLegoComponent.component_group.rows;

    const previewTextRow =
        eventRows.filter(
            (row) =>
                row.component.type === "leaf" &&
                row.component.leaf_component.type === "text" &&
                row.component.leaf_component.text_component.text_type ===
                    mergedOrgLegoStyles.event_style.name_text_type,
        )[0] ||
        eventRows
            .filter((row) => row.component.type === "leaf" && row.component.leaf_component.type === "text")
            .sort(sortTextComponentsByPriority)[0];

    return previewTextRow &&
        previewTextRow.component.type === "leaf" &&
        previewTextRow.component.leaf_component.type === "text"
        ? previewTextRow.component.leaf_component.text_component.text
        : event.name;
};

export const getEventImagePreview = (eventLegoComponent: EventLegoComponent, event: Event) => {
    const eventRows = eventLegoComponent.component_group.rows;

    const previewImageRow = eventRows.filter(
        (row) => row.component.type === "leaf" && row.component.leaf_component.type === "image",
    )[0];

    return previewImageRow &&
        previewImageRow.component.type === "leaf" &&
        previewImageRow.component.leaf_component.type === "image"
        ? previewImageRow.component.leaf_component.image_component.src
        : event?.cover_photo;
};

export const getBlockImagePreview = (group: LegoComponentGroup) => {
    const previewImageRow = group.rows.filter(
        (row) =>
            row.component.type === "leaf" &&
            row.component.leaf_component.type === "image" &&
            row.component.leaf_component.image_component.src !== emptyLegoImageSmallUrl,
    )[0];

    return previewImageRow &&
        previewImageRow.component.type === "leaf" &&
        previewImageRow.component.leaf_component.type === "image"
        ? previewImageRow.component.leaf_component.image_component.src
        : "";
};

export const getBlockTextPreview = (group: LegoComponentGroup) => {
    const { rows } = group;

    const previewTextRow = rows
        .filter(
            (row) =>
                row.component.type === "leaf" &&
                row.component.leaf_component.type === "text" &&
                row.component.leaf_component.text_component.text !== "",
        )
        .sort(sortTextComponentsByPriority)[0];

    return previewTextRow &&
        previewTextRow.component.type === "leaf" &&
        previewTextRow.component.leaf_component.type === "text"
        ? previewTextRow.component.leaf_component.text_component.text
        : "";
};

export const getInitialBlockComponents = (): PostLegoComponentBodyWithLeaf[] => {
    return [
        {
            type: "leaf",
            leaf: {
                type: "image",
                image: {
                    src: emptyLegoImageSmallUrl,
                    href: "",
                },
            },
        },
        {
            type: "leaf",
            leaf: {
                type: "text",
                text: {
                    text_type: "subheading",
                    text: "",
                },
            },
        },
        {
            type: "leaf",
            leaf: {
                type: "text",
                text: {
                    text_type: "body",
                    text: "",
                },
            },
        },
        {
            type: "leaf",
            leaf: {
                type: "button",
                button: {
                    text: "Button text",
                    href: "",
                },
            },
        },
    ];
};

const leafComponentIsEmptyOrDefault = (leafComponent: LeafLegoComponent) => {
    switch (leafComponent.type) {
        case "text":
            return leafComponent.text_component.text === "";
        case "button":
            return leafComponent.button_component.text === "" || leafComponent.button_component.text === "Button text";
        case "promo-code":
            return leafComponent.promo_code_component.text === "";
        case "image":
            return leafComponent.image_component.src === emptyLegoImageSmallUrl;
        case "divider":
        case "spacer":
        case "form":
            return false;
    }
};

export const hasAddedBlockContents = (group: LegoComponentGroup) => {
    const { rows } = group;

    if (rows.length === 0) {
        return false;
    }

    return rows.some((row) => {
        if (row.component.type === "leaf") {
            return !leafComponentIsEmptyOrDefault(row.component.leaf_component);
        }
        return false;
    });
};

export const getHorizontalEventImagePreview = (horizontalEventLegoComponent: HorizontalEventLegoComponent) => {
    return horizontalEventLegoComponent.image_leaf_lego_component.src;
};

export const convertToLegoTab = (param: string | undefined): LegoTab | undefined => {
    switch (param) {
        case "main":
        case "style":
        case "analytics":
            return param;
        default:
            return;
    }
};

export const getFirstImageUrl = (bodyComponentGroup: LegoComponentGroup): string => {
    for (const { component } of bodyComponentGroup.rows) {
        switch (component.type) {
            case "leaf": {
                if (component.leaf_component.type === "image") {
                    return component.leaf_component.image_component.src;
                }

                continue;
            }
            case "event": {
                const firstImage = getFirstImageUrl(component.event_component.component_group);
                if (!!firstImage) {
                    return firstImage;
                }

                continue;
            }
            case "horizontal-event":
                return component.horizontal_event_component.image_leaf_lego_component.src;
            case "column": {
                if (component.column_component.first_column !== null) {
                    const firstColumnImage = getFirstImageUrl(component.column_component.first_column);
                    if (!!firstColumnImage) {
                        return firstColumnImage;
                    }
                }

                if (component.column_component.second_column !== null) {
                    const secondColumnImage = getFirstImageUrl(component.column_component.second_column);
                    if (!!secondColumnImage) {
                        return secondColumnImage;
                    }
                }

                if (component.column_component.third_column !== null) {
                    const thirdColumnImage = getFirstImageUrl(component.column_component.third_column);
                    if (!!thirdColumnImage) {
                        return thirdColumnImage;
                    }
                }

                continue;
            }
            case "automation-triggered-event":
            case "automation-upcoming-events":
                continue;
        }
    }

    return "";
};

export const createNegativeLegoComponent = (id: number, body: PostLegoComponentBody): LegoComponent => {
    const { type } = body;
    switch (type) {
        case "leaf":
            return {
                id,
                type: "leaf",
                leaf_component: createNegativeLeafLegoComponent(id, body),
                padding_style: null,
            };
        case "event":
            return {
                id,
                type: "event",
                event_component: createNegativeEventLegoComponent(id, -1, body),
                padding_style: null,
            };
        case "column":
            return {
                id,
                type: "column",
                column_component: createNegativeColumnLegoComponent(id, body),
                padding_style: null,
            };
        case "horizontal-event":
            return {
                id,
                type: "horizontal-event",
                horizontal_event_component: createNegativeHorizontalEventLegoComponent(id, -1, body),
                padding_style: null,
            };
        case "automation-upcoming-events":
            return {
                id,
                type: "automation-upcoming-events",
                automation_upcoming_events_component: createNegativeAutomationUpcomingEventsLegoComponent(id, body),
                padding_style: null,
            };
        case "automation-triggered-event":
            return {
                id,
                type: "automation-triggered-event",
                automation_triggered_event_component: createNegativeAutomationTriggeredEventLegoComponent(id, body),
                padding_style: null,
            };
    }
};

const createNegativeLeafLegoComponent = (id: number, { leaf }: PostLegoComponentBodyWithLeaf): LeafLegoComponent => {
    const { type: leafType } = leaf;
    switch (leafType) {
        case "text":
            return {
                id,
                type: "text",
                text_component: {
                    id,
                    text: leaf.text.text ?? "",
                    text_type: leaf.text.text_type,
                    style: null,
                    text_configs: null,
                },
            };
        case "button":
            return {
                id,
                type: "button",
                button_component: {
                    id,
                    text: leaf.button.text ?? "",
                    href: leaf.button.href,
                    style: null,
                },
            };
        case "promo-code":
            return {
                id,
                type: "promo-code",
                promo_code_component: {
                    id,
                    text: leaf.promo_code.text ?? "",
                    style: null,
                },
            };
        case "image":
            return {
                id,
                type: "image",
                image_component: {
                    id,
                    src: leaf.image.src,
                    href: leaf.image.href,
                    max_height_px: leaf.image.max_height_px ?? null,
                    style: null,
                },
            };
        case "divider":
            return {
                id,
                type: "divider",
                divider_component: {
                    id,
                    style: null,
                },
            };
        case "spacer":
            return {
                id,
                type: "spacer",
                spacer_component: {
                    id,
                    spacing_px: leaf.spacer.spacing_px,
                },
            };
        case "form":
            return {
                id,
                type: "form",
                form_component: {
                    id,
                    name: leaf.form.name ?? "",
                    collect_email: leaf.form.collect_email,
                    collect_full_name: leaf.form.collect_full_name,
                    collect_phone: leaf.form.collect_phone,
                    button_text: leaf.form.button_text,
                    tags: leaf.form.tags ?? [],
                },
            };
    }
};

export const createNegativeEventLegoComponent = (
    id: number,
    groupId: number,
    { event }: PostLegoComponentBodyWithEvent,
): EventLegoComponent => {
    return {
        id,
        event: event.event,
        component_group: {
            id: groupId,
            rows: event.group.map((postComponent, i) => ({
                id: -1 * (i + 1),
                ordinal: i + 1,
                component: {
                    id: -1 * (i + 1),
                    type: "leaf",
                    leaf_component: createNegativeLeafLegoComponent(-1, postComponent),
                    padding_style: null,
                },
            })),
        },
    };
};

export const createNegativeHorizontalEventLegoComponent = (
    id: number,
    groupId: number,
    { horizontal_event }: PostLegoComponentBodyWithHorizontalEvent,
): HorizontalEventLegoComponent => ({
    id,
    event: horizontal_event.event,
    component_group: {
        id: groupId,
        rows: horizontal_event.group.map((postComponent, i) => ({
            id: -1 * (i + 1),
            ordinal: i + 1,
            component: {
                id: -1 * (i + 1),
                type: "leaf",
                leaf_component: createNegativeLeafLegoComponent(-1, postComponent),
                padding_style: null,
            },
        })),
    },
    image_leaf_lego_component: { ...horizontal_event.image, id: 0, style: null, max_height_px: null },
    image_alignment: horizontal_event.image_alignment,
});

const createNegativeAutomationUpcomingEventsLegoComponent = (
    id: number,
    { automation_upcoming_events }: PostLegoComponentBodyWithAutomationUpcomingEvents,
): AutomationUpcomingEventsLegoComponent => ({
    id,
    event_arrangement: automation_upcoming_events.event_arrangement,
    event_lookahead_amount: automation_upcoming_events.event_lookahead_amount,
    event_lookahead_unit: automation_upcoming_events.event_lookahead_unit,
    event_order_by: automation_upcoming_events.event_order_by,
    event_order_by_direction: automation_upcoming_events.event_order_by_direction,
    max_events: automation_upcoming_events.max_events,
    tags: automation_upcoming_events.tags.map((tag, i) => ({ ...tag, id: -1 * (i + 1) })),
    genres: automation_upcoming_events.genres.map((genre, i) => ({ ...genre, id: -1 * (i + 1) })),
});

const createNegativeAutomationTriggeredEventLegoComponent = (
    id: number,
    { automation_triggered_event }: PostLegoComponentBodyWithAutomationTriggeredEvent,
): AutomationTriggeredEventLegoComponent => ({
    id,
    event_arrangement: automation_triggered_event.event_arrangement,
});

const createNegativeColumnLegoComponent = (
    id: number,
    { column }: PostLegoComponentBodyWithColumn,
): ColumnLegoComponent => {
    return {
        id,
        first_column: {
            id: -1,
            rows: [
                {
                    id: -1,
                    component: createNegativeLegoComponent(-1, column.first_column[0]),
                    ordinal: 1,
                },
            ],
        },
        second_column: {
            id: -1,
            rows: [
                {
                    id: -1,
                    component: createNegativeLegoComponent(-1, column.second_column[0]),
                    ordinal: 1,
                },
            ],
        },
        ...(column.column_type === "three"
            ? {
                  column_type: "three",
                  third_column: {
                      id: -1,
                      rows: [
                          {
                              id: -1,
                              component: createNegativeLegoComponent(-1, column.third_column[0]),
                              ordinal: 1,
                          },
                      ],
                  },
              }
            : {
                  column_type: "two",
                  third_column: null,
              }),
        first_column_vertical_alignment: "middle",
        second_column_vertical_alignment: "middle",
        third_column_vertical_alignment: "middle",
    };
};

export const createNegativeLegoComponentId = (rows: LegoComponentGroupRow[]) => {
    let largestNegative = 0;
    for (const { component } of rows) {
        if (component.id < largestNegative) {
            largestNegative = component.id;
        }
    }

    return largestNegative;
};

export const emptyLegoImageUrl = getImageUrl("/assets/manager/event-image-filler.webp");
export const emptyLegoImageSmallUrl = getImageUrl("/assets/manager/lego-image-filler-small.webp");

export const getAutomationUpcomingEventsComponentPreviewComponents = ({
    eventArrangement,
    maxEvents,
    mergedOrgLegoStyles,
}: {
    eventArrangement: LegoEventArrangement;
    maxEvents: number;
    mergedOrgLegoStyles: MergedOrganizationLegoStyles;
}): LegoComponentPkg[] => {
    const actualMaxEvents = Math.min(Math.max(maxEvents, 1), 8);

    const components: LegoComponentPkg[] = [];
    for (let eventNumber = 1; eventNumber <= actualMaxEvents; eventNumber++) {
        components.push(
            ...getFakeEventComponent({
                eventArrangement:
                    eventArrangement === "horizontal-zigzag"
                        ? eventNumber % 2 === 1
                            ? "horizontal-left"
                            : "horizontal-right"
                        : eventArrangement,
                eventStyle:
                    eventArrangement === "vertical"
                        ? mergedOrgLegoStyles.event_style
                        : mergedOrgLegoStyles.horizontal_event_style,
                eventNumber: actualMaxEvents === 1 ? undefined : eventNumber,
            }),
        );
    }

    return components;
};

export const getAutomationTriggeredEventComponentPreviewComponents = ({
    eventArrangement,
    mergedOrgLegoStyles,
}: {
    eventArrangement: SingleLegoEventArrangement;
    mergedOrgLegoStyles: MergedOrganizationLegoStyles;
}): LegoComponentPkg[] =>
    getFakeEventComponent({
        eventArrangement,
        eventStyle:
            eventArrangement === "vertical"
                ? mergedOrgLegoStyles.event_style
                : mergedOrgLegoStyles.horizontal_event_style,
        isTriggered: true,
    });

const getFakeEventComponent = ({
    eventArrangement,
    eventStyle,
    eventNumber,
    isTriggered = false,
}: {
    eventArrangement: SingleLegoEventArrangement;
    eventStyle: NonNullableLegoEventStyle;
    eventNumber?: number;
    isTriggered?: boolean;
}): LegoComponentPkg[] => {
    const imageComponent: LeafLegoComponentPkg = {
        type: "image",
        src: emptyLegoImageUrl,
        height: 825,
        width: 825,
    };

    const eventText = isTriggered
        ? "Triggered event"
        : `Upcoming event${eventNumber !== undefined ? ` ${eventNumber}` : ""}`;

    const sortedComponents = sortByEventOrdinals<LeafLegoComponentPkg>(
        {
            image: eventArrangement === "vertical" ? imageComponent : undefined,
            name: {
                type: "text",
                text: `${eventText} name`,
                text_type: eventStyle.name_text_type,
            },
            datetime: {
                type: "text",
                text: `${eventText} time`,
                text_type: eventStyle.datetime_text_type,
            },
            button: {
                type: "button",
                text: eventStyle.button_text,
                href: "",
            },
            location: {
                type: "text",
                text: `${eventText} location`,
                text_type: eventStyle.location_text_type,
            },
            description: {
                type: "text",
                text: `This is the ${eventText.toLocaleLowerCase()} description.`,
                text_type: eventStyle.description_text_type,
            },
        },
        eventStyle,
    );

    if (eventArrangement === "vertical") {
        return sortedComponents;
    }

    return [
        {
            type: "column",
            column_type: "two",
            format: "default",
            first_column: eventArrangement === "horizontal-left" ? [imageComponent] : sortedComponents,
            second_column: eventArrangement === "horizontal-left" ? sortedComponents : [imageComponent],
            third_column: null,
            first_column_vertical_alignment: "middle",
            second_column_vertical_alignment: "middle",
            third_column_vertical_alignment: "middle",
        },
    ];
};

export const swapVerticalAlignmentOnColumnReorder = (
    newIndex: number,
    oldIndex: number,
    columnLegoComponent: ColumnLegoComponent,
) => {
    if (oldIndex === 0 && newIndex === 1) {
        const tempAlignment = columnLegoComponent.first_column_vertical_alignment;
        columnLegoComponent.first_column_vertical_alignment = columnLegoComponent.second_column_vertical_alignment;
        columnLegoComponent.second_column_vertical_alignment = tempAlignment;
    }
    if (oldIndex === 0 && newIndex === 2) {
        const tempAlignment = columnLegoComponent.first_column_vertical_alignment;
        columnLegoComponent.first_column_vertical_alignment = columnLegoComponent.second_column_vertical_alignment;
        columnLegoComponent.second_column_vertical_alignment = columnLegoComponent.third_column_vertical_alignment;
        columnLegoComponent.third_column_vertical_alignment = tempAlignment;
    }
    if (oldIndex === 1 && newIndex === 2) {
        const tempAlignment = columnLegoComponent.second_column_vertical_alignment;
        columnLegoComponent.second_column_vertical_alignment = columnLegoComponent.third_column_vertical_alignment;
        columnLegoComponent.third_column_vertical_alignment = tempAlignment;
    }
    if (oldIndex === 1 && newIndex === 0) {
        const tempAlignment = columnLegoComponent.second_column_vertical_alignment;
        columnLegoComponent.second_column_vertical_alignment = columnLegoComponent.first_column_vertical_alignment;
        columnLegoComponent.first_column_vertical_alignment = tempAlignment;
    }
    if (oldIndex === 2 && newIndex === 1) {
        const tempAlignment = columnLegoComponent.third_column_vertical_alignment;
        columnLegoComponent.third_column_vertical_alignment = columnLegoComponent.second_column_vertical_alignment;
        columnLegoComponent.second_column_vertical_alignment = tempAlignment;
    }
    if (oldIndex === 2 && newIndex === 0) {
        const tempAlignment = columnLegoComponent.third_column_vertical_alignment;
        columnLegoComponent.third_column_vertical_alignment = columnLegoComponent.second_column_vertical_alignment;
        columnLegoComponent.second_column_vertical_alignment = columnLegoComponent.first_column_vertical_alignment;
        columnLegoComponent.first_column_vertical_alignment = tempAlignment;
    }
};

export const createDummyGroupWithComponent = (component: LegoComponent): LegoComponentGroup => ({
    id: 0,
    rows: [
        {
            id: 0,
            ordinal: 1,
            component,
        },
    ],
});
