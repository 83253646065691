import clsx from "clsx";
import React from "react";

import { makeStyles } from "../styles/makeStyles";

const useStyles = makeStyles<{
    color?: TextButtonColor;
    textAlign?: TextAlign;
    fontSize?: string;
    fontWeight?: 300 | 400;
}>()((theme, { color = "midGray", fontSize = "1rem", textAlign = "center", fontWeight = 400 }) => ({
    textButton: {
        margin: 0,
        padding: `${theme.spacing(1)} 0`,
        cursor: "pointer",
        color: color === "midGray" ? theme.palette.midGray : theme.palette.primary.main,
        border: 0,
        outline: 0,
        borderRadius: 0,
        textAlign,
        backgroundColor: "transparent",
        fontFamily: "Lexend",
        fontSize,
        fontWeight,
        "&.disabled": {
            pointerEvents: "none",
            color: color === "midGray" ? "rgba(255, 255, 255, 0.25)" : theme.palette.midGray,
        },
    },
}));

type TextButtonColor = "primary" | "midGray";

type TextAlign = "left" | "right" | "center";

interface BaseProps {
    className?: string;
    isDisabled?: boolean;
    color?: "primary" | "midGray";
    textAlign?: TextAlign;
    fontSize?: string;
    fontWeight?: 300 | 400;
    onMouseDown?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

interface ButtonProps extends BaseProps {
    onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

interface AnchorProps extends BaseProps {
    href?: string;
    target?: string;
    rel?: string;
    onClick?: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
}

export const TextButton = React.forwardRef<HTMLButtonElement, React.PropsWithChildren<ButtonProps>>(
    (
        { className, isDisabled = false, color, fontSize, fontWeight, textAlign, children, onClick, onMouseDown },
        ref,
    ) => {
        const { classes } = useStyles({ color, fontSize, textAlign, fontWeight });

        return (
            <button
                ref={ref}
                className={clsx(
                    className,
                    "cymbal-text-button",
                    classes.textButton,
                    isDisabled ? "disabled" : undefined,
                )}
                onClick={onClick}
                onMouseDown={onMouseDown}
            >
                {children}
            </button>
        );
    },
);

export const AnchorTextButton = React.forwardRef<HTMLAnchorElement, React.PropsWithChildren<AnchorProps>>(
    ({ className, isDisabled = false, color, fontSize, textAlign, href, target, rel, children, onClick }, ref) => {
        const { classes } = useStyles({ color, fontSize, textAlign });

        return (
            <a
                ref={ref}
                className={clsx(
                    className,
                    "cymbal-text-button",
                    classes.textButton,
                    isDisabled ? "disabled" : undefined,
                )}
                target={target}
                rel={rel}
                onClick={onClick}
                href={href}
            >
                {children}
            </a>
        );
    },
);
