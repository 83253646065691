import CssBaseline from "@mui/material/CssBaseline";
import ThemeProvider from "@mui/material/styles/ThemeProvider";
import App, { AppInitialProps, AppProps } from "next/app";
import React from "react";
import requestIp from "request-ip";
import { createEmotionSsrAdvancedApproach } from "tss-react/next";

import { FreeTrialExpiredDialog } from "../shared/components/freeTrialExpiredDialog";
import TopProgressBar from "../shared/components/topProgressBar";
import { GitbookTokenInfo } from "../shared/models/gitbookTokenInfo";
import { IPLocation } from "../shared/models/ipLocation";
import { OrganizationsSessionInfo } from "../shared/models/sessionInfo";
import { UserAccessInfo } from "../shared/models/user";
import { ActiveFeatureFlags, AppContext } from "../shared/state/appContext";
import { theme } from "../shared/styles/theme";
import { apiGet, clientApiGet, clientApiPost } from "../shared/util/api";
import {
    clearClientPublicToken,
    clearClientSessionToken,
    getClientGitbookTokenInfo,
    setClientGitbookTokenInfo,
    setClientOrganizationId,
    setClientSessionToken,
} from "../shared/util/cookies";
import { getCookieLegosStyleCustomColors, setCookieLegosStyleCustomColors } from "../shared/util/legos";
import { getOrganizationsSessionInfoFromContextNoPublicToken, isCrawlerUserAgent } from "../shared/util/serverSide";
import { getIsMobile } from "../shared/util/userAgent";
import { ErrorBoundary } from "../src/errorPage/errorBoundary";
import "../styles/globalStyles.css";

const { augmentDocumentWithEmotionCache, withAppEmotionCache } = createEmotionSsrAdvancedApproach({
    key: "mui",
    prepend: true,
});

interface OrganizationFeatureFlag {
    slug: string;
    enabled: boolean;
}

interface AdditionalProps {
    sessionInfo?: OrganizationsSessionInfo;
    isMobile: boolean;
    ipLocation?: IPLocation;
    activeFeatureFlags: ActiveFeatureFlags;
}

interface InitialProps extends AppInitialProps, AdditionalProps {}

interface Props extends AppProps, AdditionalProps {}

export const unchangingColors = ["#000000", "#FFFFFF"];

function MyApp({
    Component,
    pageProps,
    sessionInfo,
    isMobile,
    ipLocation,
    activeFeatureFlags: initialActiveFeatureFlags,
}: Props) {
    const [user, setUser] = React.useState(sessionInfo?.user);
    const [mounted, setMounted] = React.useState(false);
    const [showFreeTrialExpiredDialog, setShowFreeTrialExpiredDialog] = React.useState(false);
    const [userOwnsTickets, setUserOwnsTickets] = React.useState(false);
    const [organization, setOrganization] = React.useState(
        sessionInfo && sessionInfo.organizations.length !== 0 ? sessionInfo.selectedOrg : undefined,
    );
    const [organizations, setOrganizations] = React.useState(sessionInfo?.organizations ?? []);
    const [gitbookTokenInfo, setGitbookTokenInfo] = React.useState<GitbookTokenInfo>();
    const [activeFeatureFlags, setActiveFeatureFlags] = React.useState<ActiveFeatureFlags>(initialActiveFeatureFlags);
    const [customColors, setCustomColors] = React.useState<string[]>([]);

    const saveCustomColor = React.useCallback((color: string) => {
        setCustomColors((prevColors) => {
            if (!unchangingColors.includes(color) && !prevColors.includes(color)) {
                const newCustomColors = [color, ...prevColors].slice(0, 5);
                setCookieLegosStyleCustomColors(newCustomColors);
                return newCustomColors;
            }
            return prevColors;
        });
    }, []);

    React.useEffect(() => {
        if (!!sessionInfo) {
            setClientSessionToken(sessionInfo.sessionToken);
        } else {
            clearClientSessionToken();
            clearClientPublicToken();
        }
        setCustomColors(getCookieLegosStyleCustomColors());

        setMounted(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
        if (organization?.id !== undefined) {
            setClientOrganizationId(organization.id);
        }
    }, [organization?.id]);

    React.useEffect(() => {
        if (!user?.super_admin && organization?.has_free_trial_expired !== undefined) {
            setShowFreeTrialExpiredDialog(organization.has_free_trial_expired);
        }
    }, [organization?.has_free_trial_expired, user?.super_admin]);

    React.useEffect(() => {
        if (!user) {
            return;
        }

        const checkUserAccessInfo = async () => {
            try {
                const userAccessInfo = await clientApiGet<UserAccessInfo>("/user/access_info");
                if (!!userAccessInfo) {
                    setUserOwnsTickets(userAccessInfo.owns_tickets);
                }
            } catch {}
        };

        const getOrCreateGitbookToken = async () => {
            const tokenInfo = getClientGitbookTokenInfo();
            if (!!tokenInfo && new Date(tokenInfo.expiry).getTime() > new Date().getTime()) {
                setGitbookTokenInfo(tokenInfo);
                return;
            }

            try {
                const gitbookTokenInfo = await clientApiPost<GitbookTokenInfo>("/gitbooks/token");
                if (!!gitbookTokenInfo) {
                    setClientGitbookTokenInfo(gitbookTokenInfo);
                    setGitbookTokenInfo(gitbookTokenInfo);
                }
            } catch {}
        };

        checkUserAccessInfo();
        getOrCreateGitbookToken();
    }, [user]);

    const reloadActiveFeatureFlags = React.useCallback(async () => {
        if (organization?.id === undefined) {
            return;
        }

        try {
            const orgFeatureFlags = await clientApiGet<OrganizationFeatureFlag[]>(
                `/org/${organization.id}/feature_flags`,
            );
            if (orgFeatureFlags === undefined) {
                return;
            }

            setActiveFeatureFlags(parseActiveFeatureFlags(orgFeatureFlags));
        } catch {}
    }, [organization?.id]);

    const prevOrganizationId = React.useRef(organization?.id);
    React.useEffect(() => {
        if (prevOrganizationId.current !== organization?.id) {
            reloadActiveFeatureFlags();
        }

        prevOrganizationId.current = organization?.id;
    }, [organization?.id, reloadActiveFeatureFlags]);

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <AppContext.Provider
                value={{
                    user,
                    mounted,
                    organization,
                    isMobile,
                    ipLocation,
                    organizations,
                    userOwnsTickets,
                    gitbookTokenInfo,
                    activeFeatureFlags,
                    customColors,
                    saveCustomColor,
                    resetUser: setUser,
                    resetOrganization: setOrganization,
                    resetOrganizations: setOrganizations,
                    resetGitbookTokenInfo: setGitbookTokenInfo,
                }}
            >
                <TopProgressBar />
                <ErrorBoundary>
                    <Component {...pageProps} />
                    {showFreeTrialExpiredDialog && organization && (
                        <FreeTrialExpiredDialog organization={organization} />
                    )}
                </ErrorBoundary>
            </AppContext.Provider>
        </ThemeProvider>
    );
}

MyApp.getInitialProps = async (ctx: any): Promise<InitialProps> => {
    const initialProps = await App.getInitialProps(ctx);

    const [sessionInfo, ipLocation] = await Promise.all([getSessionInfo(ctx), getIpLocation(ctx)]);

    let activeFeatureFlags: ActiveFeatureFlags = {
        allowEmailCampaigns: false,
        allowSmsCampaigns: false,
        allowSocialMedia: false,
        allowRichTextEditing: false,
    };
    if (!!sessionInfo) {
        const { selectedOrg, organizations } = sessionInfo;
        const organization = selectedOrg ?? (organizations.length !== 0 ? organizations[0] : undefined);
        if (!!organization) {
            try {
                const orgFeatureFlags = await apiGet<OrganizationFeatureFlag[]>(
                    `/org/${organization.id}/feature_flags`,
                );

                activeFeatureFlags = parseActiveFeatureFlags(orgFeatureFlags);
            } catch {}
        }
    }

    const userAgent = typeof window === "undefined" ? ctx.ctx.req.headers["user-agent"] : window.navigator.userAgent;

    return {
        ...(sessionInfo ? { sessionInfo } : {}),
        isMobile: getIsMobile(userAgent),
        ...(ipLocation ? { ipLocation } : {}),
        activeFeatureFlags,
        ...initialProps,
    };
};

const getSessionInfo = async (ctx: any): Promise<OrganizationsSessionInfo | undefined> => {
    if (typeof window !== "undefined") {
        return;
    }

    return getOrganizationsSessionInfoFromContextNoPublicToken(ctx.ctx);
};

const getIpLocation = async (ctx: any) => {
    const ipAddress = getIpAddress(ctx);
    if (
        typeof window !== "undefined" ||
        ctx.ctx.pathname === "/healthcheck" ||
        ctx.ctx.pathname === "/robots.txt" ||
        !ipAddress ||
        ipAddress === "::1" ||
        ipAddress === "127.0.0.1" ||
        isCrawlerUserAgent(ctx.ctx)
    ) {
        return;
    }

    try {
        return await apiGet<IPLocation>("/location", {
            args: {
                ip_address: ipAddress,
            },
            shouldAlert: () => false,
        });
    } catch {}
};

const getIpAddress = (ctx: any) => {
    try {
        return requestIp.getClientIp(ctx.ctx.req);
    } catch {
        return null;
    }
};

const parseActiveFeatureFlags = (orgFeatureFlags: OrganizationFeatureFlag[]): ActiveFeatureFlags => {
    const activeFeatureFlags: ActiveFeatureFlags = {
        allowEmailCampaigns: false,
        allowSmsCampaigns: false,
        allowSocialMedia: false,
        allowRichTextEditing: false,
    };

    for (const { slug, enabled } of orgFeatureFlags) {
        switch (slug) {
            case "email-campaigns":
                activeFeatureFlags.allowEmailCampaigns = enabled;
                continue;
            case "sms-campaigns":
                activeFeatureFlags.allowSmsCampaigns = enabled;
                continue;
            case "social-media":
                activeFeatureFlags.allowSocialMedia = enabled;
                continue;
            case "rich-text-editing":
                activeFeatureFlags.allowRichTextEditing = enabled;
                continue;
            default:
                continue;
        }
    }

    return activeFeatureFlags;
};

export { augmentDocumentWithEmotionCache };

export default withAppEmotionCache(MyApp);
