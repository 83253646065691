import addHours from "date-fns/addHours";
import differenceInHours from "date-fns/differenceInHours";

export const yearMonthDayDate = (date: Date) => new Date(date.getFullYear(), date.getMonth(), date.getDate());

export const createDateFromHourAndMinutes = (hours: number, minutes: number) => {
    const now = new Date();
    return new Date(now.getFullYear(), now.getMonth(), now.getDate(), hours, minutes);
};

const hourMinuteTimeDate = (date: Date) =>
    new Date(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes());

const yearMonthDayHourDate = (date: Date) =>
    new Date(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours());

interface CreateDateInput {
    startDate: Date | undefined;
    startTime: Date | undefined;
    endDate: Date | undefined;
    endTime: Date | undefined;
    timeZone: string | undefined;
}

export const createDateInfo = ({
    startDate,
    startTime,
    endDate,
    endTime,
    timeZone,
}: CreateDateInput): { startDateTime?: string; endDateTime?: string } => {
    let startDateTime: string | undefined;
    if (startDate && startTime) {
        startDateTime = combineDateAndTime(startDate, startTime, timeZone).toISOString();
    }

    let endDateTime: string | undefined;
    if (endDate && endTime) {
        const endDateTimeUnadjusted = new Date(
            endDate.getFullYear(),
            endDate.getMonth(),
            endDate.getDate(),
            endTime.getHours(),
            endTime.getMinutes(),
        );

        endDateTime = timeZone
            ? adjustForTimezone(endDateTimeUnadjusted, timeZone).toISOString()
            : endDateTimeUnadjusted.toISOString();
    }

    return { startDateTime, endDateTime };
};

export const combineDateAndTime = (startDate: Date, startTime: Date, timeZone?: string) => {
    const startDateTimeUnadjusted = new Date(
        startDate.getFullYear(),
        startDate.getMonth(),
        startDate.getDate(),
        startTime.getHours(),
        startTime.getMinutes(),
    );

    return timeZone ? adjustForTimezone(startDateTimeUnadjusted, timeZone) : startDateTimeUnadjusted;
};

export const adjustForTimezone = (date: Date, timeZone: string, forward = false) =>
    addHours(date, timeZoneDifference(date, timeZone, forward));

export const timeZoneDifference = (date: Date, timeZone: string, forward = false) => {
    const timezoneChangeDate = new Date(date.toLocaleString("en-US", { timeZone: timeZone || undefined }));

    return forward
        ? differenceInHours(yearMonthDayHourDate(timezoneChangeDate), yearMonthDayHourDate(date))
        : differenceInHours(yearMonthDayHourDate(date), yearMonthDayHourDate(timezoneChangeDate));
};

export const getDayDate = (dateTime: string | Date | null | undefined, timeZone: string | undefined) => {
    if (dateTime) {
        const date = typeof dateTime === "string" ? new Date(dateTime) : dateTime;
        return yearMonthDayDate(timeZone ? adjustForTimezone(date, timeZone, true) : date);
    }
};

export const getDateTime = (dateTime: string | Date | null | undefined, timeZone: string | undefined) => {
    if (dateTime) {
        const date = typeof dateTime === "string" ? new Date(dateTime) : dateTime;
        return hourMinuteTimeDate(
            timeZone ? addHours(hourMinuteTimeDate(date), timeZoneDifference(date, timeZone, true)) : date,
        );
    }
};

export const startEndSelectionIsInvalid = ({
    hasStartTime,
    startDate,
    startTime,
    hasEndTime,
    endDate,
    endTime,
}: {
    hasStartTime: boolean;
    startDate: Date | undefined;
    startTime: Date | undefined;
    hasEndTime: boolean;
    endDate: Date | undefined;
    endTime: Date | undefined;
}) =>
    (hasStartTime && (!startDate || !startTime)) ||
    (hasEndTime && (!endDate || !endTime)) ||
    (hasStartTime &&
        hasEndTime &&
        startDate &&
        endDate &&
        startTime &&
        endTime &&
        datesEqual(startDate, endDate) &&
        endTime.getTime() <= startTime.getTime());

export const datesEqual = (a: Date | string | null | undefined, b: Date | string | null | undefined) => {
    if (!!a && !!b) {
        const dateA = typeof a === "string" ? new Date(a) : a;
        const dateB = typeof b === "string" ? new Date(b) : b;

        return dateA.getTime() === dateB.getTime();
    }

    return !a && !b;
};

export const timeWithHour = (hour: number) => {
    const now = new Date();

    return new Date(now.getFullYear(), now.getFullYear(), now.getDate(), hour, 0);
};

export const createTimeSuggestionOption = (suggestion: string, timezone: string | undefined) => {
    const date = new Date(suggestion);
    const dateAdjusted = !timezone ? date : adjustForTimezone(date, timezone, true);

    return {
        hours: dateAdjusted.getHours(),
        minutes: dateAdjusted.getMinutes(),
    };
};
