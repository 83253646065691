import { useElements, useStripe } from "@stripe/react-stripe-js";
import Router from "next/router";
import React from "react";
import { Organization } from "../models/organization";
import { AppContext } from "../state/appContext";
import { makeStyles } from "../styles/makeStyles";
import { clientApiGet } from "../util/api";
import { createUrlWithDeferred } from "../util/url";
import { LoadingButton } from "./loadingButton";

const useStyles = makeStyles()((theme) => ({
    errorText: {
        fontSize: "0.75rem",
        color: theme.palette.error.main,
        padding: theme.spacing(0.5),
        paddingBottom: 0,
    },
}));

interface Props {
    organization: Organization;
}

export const FreeTrialExpiredDialogButton: React.FC<Props> = React.memo(({ organization }) => {
    const { classes } = useStyles();
    const stripe = useStripe();
    const elements = useElements();

    const [isSubmittingPaymentMethod, setIsSubmittingPaymentMethod] = React.useState(false);
    const [paymentMethodAdded, setPaymentMethodAdded] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState("");

    const { resetOrganization } = React.useContext(AppContext);

    const handleSubmit = React.useCallback(async () => {
        if (!stripe || !elements) {
            setErrorMessage("Failed to load payment elements");
            return;
        }

        setIsSubmittingPaymentMethod(true);

        const confirmSetupResult = await stripe.confirmSetup({
            elements,
            redirect: "if_required",
        });

        if (confirmSetupResult.error) {
            setErrorMessage(
                `Payment method creation failure${
                    confirmSetupResult.error.message ? `: ${confirmSetupResult.error.message}` : ""
                }`,
            );
            setIsSubmittingPaymentMethod(false);
        } else {
            setPaymentMethodAdded(true);
        }
    }, [stripe, elements]);

    React.useEffect(() => {
        if (paymentMethodAdded) {
            const interval = setInterval(async () => {
                try {
                    const result = await clientApiGet<Organization>(`/org/${organization.id}`);

                    if (result === undefined) {
                        Router.push(createUrlWithDeferred("/login"));
                        return;
                    }

                    if (!result.has_free_trial_expired) {
                        resetOrganization(result);
                        setIsSubmittingPaymentMethod(false);
                    }
                } catch (err) {
                    setErrorMessage("Unable to get org info");
                }
            }, 2_000);

            return () => clearInterval(interval);
        }
    }, [organization, paymentMethodAdded, resetOrganization]);

    return (
        <>
            <LoadingButton onClick={handleSubmit} loading={isSubmittingPaymentMethod} variant="contained" fullWidth>
                Save
            </LoadingButton>
            {!!errorMessage && <div className={classes.errorText}>{errorMessage}</div>}
        </>
    );
});
