import Button from "@mui/material/Button";
import { AlertIcon } from "@primer/octicons-react";
import { captureException } from "@sentry/nextjs";
import Router from "next/router";
import React from "react";

import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import { CymbalLogoAnchor } from "../../shared/components/cymbalLogoAnchor";
import { Div100vh } from "../../shared/components/div100vh";
import { makeStyles } from "../../shared/styles/makeStyles";

const useStyles = makeStyles()((theme) => ({
    appBar: {
        backgroundColor: theme.palette.background.default,
        borderRadius: 0,
        boxShadow: "none",
        justifyContent: "center",
        height: theme.spacing(8),
    },
    toolbar: {
        width: "100%",
        justifyContent: "center",
        padding: `0 ${theme.spacing(5)}`,
        minHeight: theme.spacing(8),
        color: theme.palette.text.primary,
    },
    errorPageWrapper: {
        width: "100%",
        height: "calc(100% - 64px)",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        overflowY: "auto",
        padding: theme.spacing(2),
        "@media (max-height: 400px)": {
            justifyContent: "flex-start",
        },
    },
    errorPageContent: {
        maxWidth: 360,
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
    },
    icon: {
        marginBottom: theme.spacing(2),
    },
    title: {
        fontSize: "1.25rem",
        marginBottom: theme.spacing(1),
    },
    subtitle: {
        fontSize: "0.875rem",
        fontWeight: 300,
        marginBottom: theme.spacing(3),
    },
    topButton: {
        marginBottom: theme.spacing(2),
    },
}));

interface Props {
    isNotFound?: boolean;
}

export const ErrorPageContent: React.FC<Props> = React.memo(({ isNotFound = false }) => {
    const { classes } = useStyles();

    React.useEffect(() => {
        if (!isNotFound) {
            captureException(`Error page rendered at ${window.location.href}`);
        }
    }, [isNotFound]);

    const title = React.useMemo(() => (isNotFound ? "Page not found" : "Something went wrong"), [isNotFound]);
    const subtitle = React.useMemo(
        () =>
            isNotFound
                ? "We could not find the page you are looking for. Please try again later or contact support."
                : "Our team has been notified of this issue. Please try again later or contact support.",
        [isNotFound],
    );

    return (
        <Div100vh>
            <AppBar className={classes.appBar} position="sticky">
                <Toolbar className={classes.toolbar}>
                    <CymbalLogoAnchor />
                </Toolbar>
            </AppBar>
            <div className={classes.errorPageWrapper}>
                <div className={classes.errorPageContent}>
                    <AlertIcon className={classes.icon} size={40} />
                    <div className={classes.title}>{title}</div>
                    <div className={classes.subtitle}>{subtitle}</div>
                    <Button
                        className={classes.topButton}
                        variant="contained"
                        color="secondary"
                        onClick={Router.back}
                        fullWidth
                    >
                        Go back
                    </Button>
                    <Button variant="contained" color="secondary" href="https://cymbal.co/help" fullWidth>
                        Contact support
                    </Button>
                </div>
            </div>
        </Div100vh>
    );
});
