import CircularProgress from "@mui/material/CircularProgress";
import DialogContent from "@mui/material/DialogContent";

import { CreditCardIcon } from "@primer/octicons-react";
import { PaymentElement } from "@stripe/react-stripe-js";
import Router from "next/router";
import React from "react";
import { StripeElements } from "../../src/settingsPage/organizationSettings/paymentInformation/stripeElements";
import { Organization } from "../models/organization";
import { BasePageContext } from "../state/basePageContext";
import { makeStyles } from "../styles/makeStyles";
import { clientApiPost } from "../util/api";
import { handleErrorToast } from "../util/toast";
import { createUrlWithDeferred } from "../util/url";
import { CymbalDialog } from "./cymbalDialog";
import { FreeTrialExpiredDialogButton } from "./freeTrialExpiredDialogSubmitButton";

const useStyles = makeStyles()((theme) => ({
    content: {
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
        gap: theme.spacing(1),
        textAlign: "center",
    },
    title: {
        fontSize: "1.25rem",
    },
    description: {
        fontSize: "0.875rem",
        fontWeight: 300,
        marginBottom: theme.spacing(2),
    },
    icon: {
        marginTop: theme.spacing(2),
        alignSelf: "center",
    },
    spinnerContainer: {
        display: "flex",
        justifyContent: "center",
        padding: theme.spacing(5),
    },
    stripeContainer: {
        marginBottom: theme.spacing(2),
    },
}));

interface Props {
    organization: Organization;
}

export const FreeTrialExpiredDialog = ({ organization }: Props) => {
    const { classes, theme } = useStyles();

    const { errorToast } = React.useContext(BasePageContext);

    const [setupIntentSecret, setSetupIntentSecret] = React.useState<string>();

    const createSetupIntent = React.useCallback(async () => {
        try {
            const response = await clientApiPost<{ client_secret: string }>(
                `/org/${organization.id}/payment_methods/setup`,
            );
            if (!response) {
                Router.push(createUrlWithDeferred("/login"));
                return;
            }

            setSetupIntentSecret(response.client_secret);
        } catch (error: any) {
            handleErrorToast({ error, message: "Failed to set up payment method data", errorToast });
        }
    }, [organization.id, errorToast]);

    React.useEffect(() => {
        createSetupIntent();
    }, [createSetupIntent]);

    return (
        <CymbalDialog isCloseHidden open isCloseDisabled>
            <DialogContent className={classes.content}>
                <CreditCardIcon className={classes.icon} size={24} />
                <div className={classes.title}>Your free trail has ended</div>
                <div className={classes.description}>
                    Please enter your credit card number to continue using Cymbal. This payment method will be charged
                    at the end of the month based on usage volume.
                </div>
                {setupIntentSecret ? (
                    <StripeElements
                        clientSecret={setupIntentSecret}
                        variables={{
                            colorBackground: theme.palette.background.paperHighlight,
                            focusOutline: undefined,
                            spacingGridRow: "16px",
                        }}
                        rules={{
                            ".Input": {
                                border: "none",
                            },
                            ".Label": {
                                color: "white",
                            },
                            ".Label--floating": {
                                color: theme.palette.midGray,
                            },
                        }}
                    >
                        <div className={classes.stripeContainer}>
                            <PaymentElement
                                options={{
                                    wallets: {
                                        applePay: "never",
                                        googlePay: "never",
                                    },
                                    terms: {
                                        card: "never",
                                    },
                                }}
                            />
                        </div>

                        <FreeTrialExpiredDialogButton organization={organization} />
                    </StripeElements>
                ) : (
                    <div className={classes.spinnerContainer}>
                        <CircularProgress size={24} />
                    </div>
                )}
            </DialogContent>
        </CymbalDialog>
    );
};
