export const createUrlQueryParamString = (
    params: Record<string, string | number | boolean | string[] | number[]>,
): string =>
    Object.keys(params)
        .reduce((urlParams, arg) => {
            const value = params[arg];
            if (Array.isArray(value)) {
                return [...urlParams, ...value.map((val) => `${encodeURIComponent(arg)}=${encodeURIComponent(val)}`)];
            }
            return [...urlParams, `${encodeURIComponent(arg)}=${encodeURIComponent(value)}`];
        }, [] as string[])
        .join("&");

export const createUrl = (base: string, params: { [param: string]: string | number | boolean } = {}) => {
    const queryParamsString = createUrlQueryParamString(params);

    return queryParamsString ? `${base}?${queryParamsString}` : base;
};

export const createUrlWithDeferred = (base: string) => {
    const pathname = window?.location?.pathname ?? "";

    const queryParamsString = createUrlQueryParamString({ d: pathname });

    return queryParamsString ? `${base}?${queryParamsString}` : base;
};

export const trimHostName = (domainName: string) => domainName.replace(/[^0-9a-zA-Z\-.]/gi, "").toLocaleLowerCase();

export const isValidUrl = (url: string, { injectHttpProtocol = false }: { injectHttpProtocol?: boolean } = {}) => {
    let urlString = url;
    if (
        injectHttpProtocol &&
        urlString.indexOf("https://") === -1 &&
        urlString.indexOf("http://") === -1 &&
        urlString.indexOf("mailto:") === -1
    ) {
        urlString = `https://${urlString}`;
    }

    //according to js, https://https://abc.com is a valid link, but we want to invalidate this case
    if ((url.match(/https?:\/\//g)?.length ?? 0) > 1) {
        return false;
    }

    try {
        const split = url.split(".");
        return split.length > 1 && split[0] !== "" && split[1] !== "" && Boolean(new URL(urlString));
    } catch {
        return false;
    }
};

export const injectMissingHttpProtocolForUrl = (url: string) =>
    !url.startsWith("https://") && !url.startsWith("http://") && !url.startsWith("mailto:") ? `https://${url}` : url;

export const isInvalidSavedArtistUrl = (url: string) => !!url && !isValidUrl(url, { injectHttpProtocol: true });

export const convertUrlToHostName = (urlString: string) => {
    try {
        return new URL(injectMissingHttpProtocolForUrl(urlString)).hostname;
    } catch {
        return urlString;
    }
};
